import { ref, watch, onMounted } from 'vue'
import { useDebounce } from '@vueuse/core'
import { Loader } from '@googlemaps/js-api-loader'
import { useErrorReporter } from '@/composables/useErrorReporter'

export function useAddressAutocompleter () {
	const { reportError } = useErrorReporter(useBugsnag().notify)
	const searchTerm = ref('')
	const searchPlaceId = ref('')
	const activeSearchItem = ref(-1)
	const resultList = ref<{desc: string, id: string}[]>([])
	const isSearchActive = ref(false)
	const dontSearch = ref(false)
	const service = ref<google.maps.places.AutocompleteService | null>(null)
	const loaderError = ref(false)
	const selectedFromList = ref(false)

	const displaySuggestions = (predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => {
		if (!google || status !== google.maps.places.PlacesServiceStatus.OK || !predictions) { return }
		resultList.value.length = 0
		predictions
			.filter(result => result.description)
			.forEach(result => resultList.value.push({ desc: result.description, id: result.place_id }))
	}

	const doSearch = async () => {
		if (!google || loaderError.value) { return }

		if (!service.value) {
			const { AutocompleteService } = await google.maps.importLibrary('places') as google.maps.PlacesLibrary
			service.value = new AutocompleteService()
		}
		if (searchTerm.value) {
			const options = {
				input: searchTerm.value,
				locationBias: {
					center: new google.maps.LatLng(39.5186, -104.7614), // Parker, CO
					radius: 80000 // 80km or ~50mi
				},
				types: [ 'address' ]
			}
			service.value.getPlacePredictions(options, displaySuggestions)
		}
	}

	const searchFocused = (event: FocusEvent) => {
		isSearchActive.value = true
		const target = event.target as HTMLInputElement | null
		target?.select()
	}

	const searchBlurred = () => {
		setTimeout(() => { isSearchActive.value = false }, 150)
	}

	const checkActiveSearchIndex = () => {
		if (activeSearchItem.value < 0) {
			activeSearchItem.value = resultList.value.length - 1
		} else if (activeSearchItem.value >= resultList.value.length) {
			activeSearchItem.value = -1
		}
	}

	const handleKeyPress = (event: KeyboardEvent) => {
		selectedFromList.value = false
		if (event.key === 'ArrowDown') {
			activeSearchItem.value++
			checkActiveSearchIndex()
		} else if (event.key === 'ArrowUp') {
			activeSearchItem.value--
			checkActiveSearchIndex()
		} else if (event.key === 'Enter') {
			if (activeSearchItem.value >= 0) {
				setSearchTerm(resultList.value[activeSearchItem.value]?.desc, resultList.value[activeSearchItem.value]?.id, activeSearchItem.value)
			}
		}
	}

	const setSearchTerm = (newSearchTerm: string, id: string, index: number) => {
		dontSearch.value = true
		searchTerm.value = newSearchTerm
		searchPlaceId.value = id
		selectedFromList.value = true
		if (index !== undefined) {
			activeSearchItem.value = index
		}
	}

	const debounced = useDebounce(searchTerm, 200)

	watch(debounced, () => {
		if (!dontSearch.value) {
			doSearch()
		}
		dontSearch.value = false
	})

	onMounted(() => {
		const loader = new Loader({ // https://developers.google.com/maps/documentation/javascript/overview#Loading_the_Maps_API
			apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
			version: 'quarterly',
			libraries: [ 'places', 'geocoding' ]
		})

		loader.load().then(async () => {
			await google.maps.importLibrary('places')
		}).catch((error) => {
			reportError('Google Maps API Error', error)
			loaderError.value = true
		})
	})

	return { setSearchTerm, searchTerm, activeSearchItem, resultList, isSearchActive, searchFocused, searchBlurred, handleKeyPress, searchPlaceId, selectedFromList }
}
